export const environment = {
  firebase: {
  },
  production: true,
  version: '0.0.1 (beta)',
  graphql_endpoint: 'https://staging-graph.vogo.storexweb.team/graphql',
  url: 'https://staging-files.vogo.storexweb.team',
  places_key: '',
  skip_auth: false,
  COMPUTED_ROUTE_MATRIX: 'https://routes.googleapis.com/distanceMatrix/v2:computeRouteMatrix',
  COMPUTED_ROUTE: 'https://routes.googleapis.com/directions/v2:computeRoutes',
  GOOGLE_SCRIPT: 'https://maps.googleapis.com/maps/api/js',
  google_api_key: 'AIzaSyBWLWsLeLXb1X7rgxwhBaxl6UnCBykjl60',
  routes_api_key: 'AIzaSyDeJ5xM143_LxsyxSacy36EVeBALiaRbdY',
  driverAndroidApp:'intent://profile/4#Intent;package=com.facebook.katana;scheme=fb;end',
  driverIOSApp:'intent://profile/4#Intent;package=com.facebook.katana;scheme=fb;end',
  STRIP_KEY:"pk_test_s6cToNHgpsPrcwPPnHu5Zevq00VN5oUuax"

};
